// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["message-alert"],depth0,{"name":"message-alert","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div id=\"email-address-group\"\r\n                class=\"form-group form-group-partial row "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.emailAddressErrorText : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                <label for=\"email\" class=\"col-sm-3 col-xs-4 no-padding-right text-left\">Registration Email\r\n                    Address:</label>\r\n                <div class=\"col-sm-7 col-xs-7\">\r\n                    <div class=\"col-sm-10 col-xs-10 no-padding\">\r\n                        <input id=\"email\" type=\"email\" autocomplete=\"off\" maxlength=\"64\"\r\n                            class=\"form-control col-xs-5 col-sm-5 "
    + alias4(((helper = (helper = helpers.EMAILADDRESS || (depth0 != null ? depth0.EMAILADDRESS : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EMAILADDRESS","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias4(container.lambda((depth0 != null ? depth0.emailAddress : depth0), depth0))
    + "\" />\r\n                    </div>\r\n                    <div class=\"col-sm-1 col-xs-1\">\r\n                        <strong>\r\n                            <span class=\"oa-banner-red\">*</span>\r\n                        </strong>\r\n                    </div>\r\n                </div>\r\n                <div id=\"email-address-error\"\r\n                    class=\"help-block col-sm-9 col-xs-8 pull-right "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.emailAddressErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                    "
    + alias4(((helper = (helper = helpers.emailAddressErrorText || (depth0 != null ? depth0.emailAddressErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emailAddressErrorText","hash":{},"data":data}) : helper)))
    + "\r\n                </div>\r\n            </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "has-error";
},"6":function(container,depth0,helpers,partials,data) {
    return "hide";
},"8":function(container,depth0,helpers,partials,data) {
    return "                            <option value=\"Full Access\" selected=\"true\">Full Access</option>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            <option value=\"Full Access\">Full Access</option>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                            <option value=\"Limited Access\" selected=\"true\">Limited Access</option>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <option value=\"Limited Access\">Limited Access</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"page-content\">\r\n    <div class=\"page-header\">\r\n        <h1>Edit Delegate</h1>\r\n    </div>\r\n    <div class=\"well well-account-info\">\r\n        <div class=\"row\">\r\n            <div class=\"col-sm-6\">\r\n                <h3>Name:&nbsp;\r\n                    <span class=\"oa-banner-blue margin-left-10\">\r\n                        "
    + alias4(((helper = (helper = helpers.producerFullName || (depth0 != null ? depth0.producerFullName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"producerFullName","hash":{},"data":data}) : helper)))
    + "\r\n                    </span>\r\n                </h3>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <p>To edit delegate access, enter or select the information below and click the Submit button.</p>\r\n    <div class=\"oa-lake\">\r\n        <span class=\"bigger-125\">Edit Delegate Information</span>\r\n        <span class=\"pull-right oa-banner-red\">* Required Fields</span>\r\n    </div>\r\n    <div class=\"clearfix form-actions margin-top-10\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alertMessage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <form class=\"form-horizontal col-sm-12\" role=\"form\" id=\"edit-delegate-form\">\r\n            <div id=\"name-group\" class=\"form-group form-group-partial row\">\r\n                <label class=\"col-sm-3 col-xs-4 no-padding-right text-left\" for=\"name\">\r\n                    Name :\r\n                </label>\r\n                <div class=\"col-sm-7 col-xs-7\">\r\n                    <div class=\"col-sm-10 col-xs-10 no-padding\">\r\n                        <input type=\"text\" autocomplete=\"off\" id=\"name\" class=\"form-control col-xs-5 col-sm-5 "
    + alias4(((helper = (helper = helpers.NAME || (depth0 != null ? depth0.NAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data}) : helper)))
    + "\"\r\n                            value=\""
    + alias4(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\" disabled />\r\n                    </div>\r\n                </div>\r\n            </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEmailAddressNotRegistered : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n            <div id=\"access-level-group\"\r\n                class=\"form-group form-group-partial row  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.accessLevelErrorText : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                <label class=\"col-sm-3 col-xs-4 no-padding-right text-left\" for=\"AccessLevel\">\r\n                    Access Level :\r\n                </label>\r\n                <div class=\"col-sm-7 col-xs-7\">\r\n                    <div class=\"col-sm-10 col-xs-10 no-padding\">\r\n                        <select id=\"accesslevel\" class=\" form-control col-xs-5 col-sm-5 "
    + alias4(((helper = (helper = helpers.ACCESSLEVEL || (depth0 != null ? depth0.ACCESSLEVEL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCESSLEVEL","hash":{},"data":data}) : helper)))
    + "\"\r\n                            name=\"accessLevel\">\r\n                            <option value=\"--please select--\">--please select--</option>\r\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.accessLevel : depth0),"Full Access",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.accessLevel : depth0),"Limited Access",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </select>\r\n                    </div>\r\n                    <div class=\"col-sm-1 col-xs-1\">\r\n                        <strong>\r\n                            <span class=\"oa-banner-red\">*</span>\r\n                        </strong>\r\n                    </div>\r\n                </div>\r\n                <div id=\"access-level-error\"\r\n                    class=\"help-block col-sm-9 col-xs-8 pull-right "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.accessLevelErrorText : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n                    "
    + alias4(((helper = (helper = helpers.accessLevelErrorText || (depth0 != null ? depth0.accessLevelErrorText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accessLevelErrorText","hash":{},"data":data}) : helper)))
    + "\r\n                </div>\r\n            </div>\r\n        </form>\r\n    </div>\r\n    <div class=\"text-left\">\r\n        <button type=\"reset\" class=\"btn btn-grey margin-right-10\" id=\"edit-delegate-cancel\">Cancel</button>\r\n        <button type=\"submit\" class=\"btn btn-primary\" id=\"edit-delegate-submit\">Submit</button>\r\n    </div>\r\n    <div class=\"col-sm-12 col-xs-12 margin-top-20\">\r\n        <p><strong>Note: </strong>A name change will require the delegate to be deleted and re-added.\r\n        </p>\r\n        <p><strong>Note: </strong>Full access allows your delegate to view all commission and general producer\r\n            information. The delegate will be able to view Commission Statements, Daily Commissions, One Statement\r\n            and Production Reports.\r\n        </p>\r\n        <p><strong>Note: </strong>Limited access restricts your delegate to view only general producer information. \r\n            The limited view will not include the link to Center of Excellence or information about commissions or benefits.\r\n        </p>\r\n    </div>\r\n</div>\r\n</div>";
},"usePartial":true,"useData":true});
