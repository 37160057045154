/* global Backbone, Marionette */
var NavbarView = require('./views/navbar-v');

/**
* A module to display the top navbar.
*
* Usage:
*
* var navbar = new NavBar({
*    region    : regionRef,  // Required reference to a region to hold the navbar
*    userName  : 'Ringo',    // Optional display name for the user
*    appName   : 'OA WebApp' // Technically optional, but you'll want to set it
* });
*
* this.listenTo(navbar, 'nav', showAPageCallback);
* this.listenTo(navbar, 'logout', logOutTheUserCallback);
*
*/
var Navbar = Marionette.Object.extend({

    errors : {
        options : 'Navbar requires options with a region',
        userNameType : 'Navbar userName option must be a string!',
        parentRegion: 'Navbar requires a region to render into!'
    },

    initialize: function(options) {

        if (!options || typeof options !== 'object') {
            throw new Error(this.errors.options);
        }

        if (!options.region) {
            throw new Error(this.errors.parentRegion);
        }

        if (options && options.userName && typeof options.userName !== 'string') {
            throw new Error(this.errors.userNameType);
        }

        var model = new Backbone.Model();
        model.set('userName', options.userName);
        model.set('appName', options.appName || 'OneAmerica App');
        model.set('homeOffice', options.homeOffice);

        // Create our view
        this.navbarView = new NavbarView({
            model : model
        });

        options.region.show(this.navbarView);

        // "nav" events from the navbar view are passed up to the parent
        this.listenTo(this.navbarView, 'nav', function (title) {
            this.trigger('nav', title);
        });

        this.listenTo(this.navbarView, 'logout', function (title) {
            this.trigger('logout');
        });   
        
        this.listenTo(this.navbarView, 'maintainDelegateAccess', function (title) {
            this.trigger('maintainDelegateAccess');
        }); 

        this.listenTo(this.navbarView, 'startProducerDelegateAccess', function (title) {
            this.trigger('startProducerDelegateAccess');
        }); 
        
        this.listenTo(this.navbarView, 'securitySettings', function (title) {
            this.trigger('securitySettings');
        }); 
    },

    /**
     * Re-render navbar view 
     */
    reRender : function reRender () {
        this.navbarView.render();
    },

    updateUserName: function updateUser(userName) {
        this.navbarView.model.set('userName', userName);
    },

    updateHO: function updateHo(homeOffice) {
        this.navbarView.model.set('homeOffice', homeOffice);
    },


    /**
     * Add producer delegate access menu options for under dropdown menu
     * 
     */
    showProducerDelegateAccessMenuOptions: 
        function showProducerDelegateAccessMenuOptions (hasProducerDelegateAccess) {
            this.navbarView.model.set('hasProducerDelegateAccess', hasProducerDelegateAccess);
        },
    
    showDelegateMaintenanceOption: function (hasDelegateMaintAccess) {
        this.navbarView.model.set('hasDelegateMaintAccess', hasDelegateMaintAccess);
    }
});

module.exports = Navbar;
