/* global Marionette:false, Backbone:false; _:false */
/**
 * A module for handling user.
 * This module should be initialized on app start. When it is first instantiated,
 * it creates a user model with default values. Call userModule.fetchUserData()
 * to have the module retrieve the user data from the service. When the fetch is
 * complete, the user module triggers a "state:ready" event.
 * 
 * usage :
 * var UserModule = require('modules/user/userModule');
 * var user = new UserModule();
 * this.listenTo(user, "state:ready", this.someStartupFunction);
 *
 * Created by Peter Rockey on 5/14/2015.
 */
 var analyticsChannel   = Backbone.Radio.channel('analytics');
 var config             = require('../../config/config');
 var errorChannel       = Backbone.Radio.channel('error');
 var ProducerRolesModel = require('../../models/producerRoles-m');
 var UserModel          = require('../../models/user-m');
 var utils              = require('../../utils/utils');
 
 
 var UserModule = Marionette.Object.extend({
 
     errors : {
         beginImpersonationError : 'beginImpersonation() requires a producerInfo.webId parameter!',
         invalidUserModel        : 'userModel did not pass validation'
     },
 
     initialize: function (options) {
         this.webId = this._readWebIdFromCookie();
 
         this.userModel = new UserModel({
             webId : this.webId
         });
 
         this.listenTo(this.userModel, 'invalid', function (model, error) {
             errorChannel.trigger('showErrorPage', 'userData: '+error[0].capabilities);
         });
 
         // Currently, producer roles should only be fetched for the user logging in to OSO.
         this.listenToOnce(this.userModel, 'change:producerId', this._fetchProducerRoles);
 
         // User Radio Channel to get user model data
         this.userChannel = Backbone.Radio.channel('user');
 
         // Register a Radio handler to check capabilities
         this.userChannel.reply('hasCapability', this.hasCapability, this);
 
         this.userChannel.reply('getDelegateTargets', this.getDelegationTargets, this);
         this.userChannel.reply('getImpersonatedWebId', this.getImpersonatedWebId, this);
         this.userChannel.reply('getCommissionInfo', this.getCommissionInfo, this);
 
         // Register handlers for view as producer
         this.userChannel.on('viewAsProducer', this.beginImpersonation, this);
         this.userChannel.on('endViewAsProducer', this.endImpersonation, this);
     },
 
     /**
      * Clean up
      */
     onBeforeDestroy : function() {
         this.userChannel.stopReplying();
     },
 
 
     /**
      * Retrieve the userModel for the logged-in user.
      */
     fetchUserData: function () {
         var _this = this;
 
         this.userModel.fetch({validate:true})
             .done(function () {
                 if (_this.userModel.isValid()) {
                     _this.listenTo(_this.userModel, 'change', _this._sendUserChangeEvent);
 
                     //trigger state ready event
                     _this.triggerMethod('state:ready');
 
                 }
             });
             // No need to have a .fail() handler here since the ajax
             // utils handle failed ajax requests.
     },
 
 
     /**
      * Get the list of capabilities for the user. If the user is in an impersonation session
      * (View as Producer, Delegate), it will return the capabilities for the impersonated user.
      * Otherwise, it will return the capabilities of the actual logged-in user.
      *
      * @returns {*}
      */
     getCapabilities: function () {
         var capabilities = [];
 
         if (this.impersonatedUserModel) {
             capabilities = this.impersonatedUserModel.get('capabilities');
 
         } else if (this.userModel) {
             capabilities = this.userModel.get('capabilities');
         }
 
         return capabilities;
     },
 
     /**
      * Returns true if the user has the specified capability.
      *
      * Like the `getCapabilities` function, the impersonation state will determine which list of
      * capabilities are consulted.
      *
      * @param capability the capability to check for
      * @param {boolean} checkInUserModel flag to check capability existence in userModel
      * 
      * @returns boolean
      */
     hasCapability: function (capability, checkInUserModel) {
         var hasCapability = false;
         
         if ((checkInUserModel || !this.impersonatedUserModel) && this.userModel) {
             hasCapability = this.userModel.hasCapability(capability);
         } else if (this.impersonatedUserModel){
             hasCapability = this.impersonatedUserModel.hasCapability(capability);
         }
         
         return hasCapability;
     },
 
     /**
      * Return the displayName from the userModel.
      * @returns {*}
      */
     getDisplayName: function() {
         if (this.userModel) {
             return this.userModel.get('displayName');
         }
     },
 
     /**
      * Returns the producerId from the userModel.
      * {@link https://oneamerica.atlassian.net/browse/OOSO-3697}
      * @returns {number|null} producerId
      */
     getProducerId : function getProducerId () {
         if (this.userModel) {
             return this.userModel.get('producerId');
         }
     },
 
     /**
      * Return the webId from the userModel
      * @returns {*}
      */
     getWebId: function() {
         if (this.userModel) {
             return this.userModel.get('webId');
         }
     },
 
     /**
      * Logout function. Clears the user model(s), and redirects to the logout url (thus clearing
      * the application from the browser).
      * @param {boolean} deepLinking   To add "target" and "smagentname" query strings
      * @param url
      *
      * @return {string} final logout URL
      */
     logout: function (deepLinking, url) {
         var encodedTargetURL;
         var querySeperator;
         if (!url) {
             url = config.apiPublicUrlRoot + 'auth/logout';
         }
 
         if (deepLinking) {
             querySeperator = url.indexOf('?') !== -1 ? '&' : '?';
             encodedTargetURL =  encodeURIComponent(Backbone.history.location.href);
 
             // Add 'smagentname' and 'target' to 'redirectUrl'
             // Which will help to redirect back to a specific page after authentication
             url = url + querySeperator + 'smagentname=api-agent&target=' + encodedTargetURL;
         }
         
         if (this.userModel) {
             this.userModel = null;
         }
 
         if (this.impersonatedUserModel) {
             this.impersonatedUserModel = null;
             //removing the cookie for targetUser.
             utils.createCookie(this.getWebId(), -1);
         }
         
        // close vintage session if exists
         this.logoutWindow = window.open(config.vintageURL + 'public/logout.faces',
         'OSO Vintage Logout'); 
         var _this = this;
         setTimeout(function() {
             if(_this.logoutWindow) {
                 _this.logoutWindow.close();
             }
             window.location.href = url;
         }, 1000);
  
     },
 
     /**
      * To logout when session timeout 
      */
     timeout : function (url) {
         if (!url) {
             url = config.apiPublicUrlRoot + 'auth/logout?timeout=true';
         }
         this.logout(true, url);
     },
 
     /**
      * Security Access Setting function. Redirects to the Security Access Settings url
      * @return {string} final securitySettings URL
      */
     securitySettings: function () {
         const baseUrl = config.oneamericaURL + '/oasec/public/ui/profileChange.html';
 
         //set relayState to home page for return from oasec page
         //add targetuser to relayState if impersonation
         var relayState = utils.addTargetUserQueryParamToURL(config.onesourceURL + '/#home');
         var encodedRelayState =  encodeURIComponent(relayState);        
             
         window.location.assign(`${baseUrl}?oaRelayState=${encodedRelayState}`);        
     },
 
     /* Impersonation functions.
      * The OSO implementation uses jQuery.prefilter to add a param (targetuser) to all ajax
      * requests if getImpersonatedWebId returns a string value. See the buildPrefilter()
      * function in ajax-utils.js for the implementation of adding the param.
      */
 
     /**
      * Return the value of the `impersonatedWebId` property. This is the webId (elad ID, global ID)
      * of the user.
      * @returns {*|null}
      */
     getImpersonatedWebId: function () {
         return this.impersonatedWebId;
     },
    
   
     getCommissionInfo: function () {
        let webId;
        webId =  this.getImpersonatedWebId();
        return this.getCommissionAccess(webId);
    },
     
     /**
      * Return the value of the impersonatedUserModel.displayName attribute
      */
     getImpersonatedUserName : function() {
         if (this.impersonatedUserModel) {
             return this.impersonatedUserModel.get('displayName');
         }
     },
 
     /**
      * Begin an impersonation session. This function will:
      * 1) Set the `impersonatedWebId` property, which will be sent with all ajax requests
      * 2) fetch a new userModel, which will use the `impersonatedWebId` to get the impersonated
      *    user's info
      * 3) Trigger a message to `osoApp` to view as producer.
      *
      * @param producerInfo the webId and fullName of the user to impersonate
      */
     beginImpersonation: function (producerInfo) {
         var _this = this;
         var displayName;
          
         this.isDelegate  = this.hasCapability('OLS:DELEGATE', true);
         this.isProducer  = this.hasCapability('OLS:PRODUCER', true);
         this.hasMultiple = this.getDelegationTargets().length > 1;
 
         if (producerInfo && producerInfo.webId) {
             this.impersonatedWebId = producerInfo.webId;
             displayName = producerInfo.fullName;
             utils.createCookie(this.impersonatedWebId);
             this.impersonatedUserModel = new UserModel({
                 webId : this.webId
             });
             this.impersonatedUserModel
                 .fetch({validate:true})
                 .done(function () {
                     if (_this.impersonatedUserModel.isValid()) {
 
                         if (!displayName) {
                             displayName = _this.impersonatedUserModel.get('displayName') 
                                 || producerInfo.webId;
                         } 
 
                         _this.impersonatedUserModel.set('displayName', displayName);
                         _this.listenTo(
                             _this.impersonatedUserModel,
                             'change',
                             _this._sendUserChangeEvent
                         );
 
                         //trigger state ready event
                         _this.trigger('viewAsProducer', 
                             _this.isDelegate, 
                             _this.hasMultiple, 
                             _this.isProducer,
                             producerInfo.redirectHash
                         );
 
                         // Start tracking as impersonated user
                         analyticsChannel.trigger('setViewingAs', _this.impersonatedWebId);
                     } else {
                         throw new Error(this.errors.invalidUserModel + ': '
                             + _this.impersonatedUserModel.validationError );
                     }
                 });
 
         } else {
             throw new Error(this.errors.beginImpersonationError);
         }
     },
 
     /**
      * 1) set the `impersonatedWebId` property to null.
      * 2) move the `actualUserModel` back to `userModel`
      * 3) Trigger a message to end the view as producer session
      *
      * @param {boolen} [redirectFlag] Optional flag to decide whether redirection require while
      *                                ending impersonation
      */
     endImpersonation: function (redirectFlag) {
 
         this.impersonatedWebId = null;
         this.impersonatedUserModel = null;
         //removing the cookie for targetUser.
         utils.createCookie(this.getWebId(), -1);

         this.trigger('endViewAsProducer', 
             this.isDelegate, this.hasMultiple, this.isProducer, redirectFlag);
 
         this.isDelegate = null;
         this.isProducer = null;
         this.hasMultiple = null;
 
         // Stop tracking as impersonated user
         analyticsChannel.trigger('setViewingAs', null);
     },
 
     /**
      * Retrieve the delegation targets for the user. This function will filter out any producers
      * who do not have an active role code (but will still include those inactive role codes in the
      * producer entry. The count of these items can be useful to determine if an action should take
      * a delegate user directly into impersonation or to a selection view.
      *
      * This function will also store the result of the filtering so that the logic does not need to
      * be repeated.
      *
      * @returns {Array} filtered array of items from the user model's "delegation" property, or []
      */
     getDelegationTargets : function getDelegationTargets() {
         var delegation   = this.userModel.get('delegation');
 
         if (! this.validTargets) {
             this.validTargets = [];
 
             if (delegation && delegation.length) {
 
                 // filter the list to include only producers who have at least one active role code
                 this.validTargets = delegation.filter(function(producer) {
                     return _.any(producer.producer.roles, function(role) {
                         return (role.statusCode === 'A');
                     });
                 });
             }
         }
 
         return this.validTargets;
     },
 
     /**
      * Provide delegation user data based on webId
      * @param  {string} webId 
      * @return {object}       producer data which match webId
      */
     getDelegationTarget : function getDelegationTarget (webId) {
         if (!webId || (!this.validTargets && !this.getDelegationTargets())) {
             return null;
         } 
 
         var delegationTarget = null;
         var filterTargets = _.filter(this.validTargets,function (targets) {
             return targets.producer.webId=== webId; 
         });
 
         if (filterTargets && filterTargets.length > 0) {
             delegationTarget = filterTargets[0].producer;
         }
 
         return delegationTarget;
     },

     getCommissionAccess: function getCommissionAccess(webId) {
        
        if (!webId || (!this.validTargets && !this.getDelegationTargets())) {
            return null;
        } 

        var commissionAccess = null;
        var filterTargets = _.filter(this.validTargets,function (targets) {
            return targets.producer.webId=== webId; 
        });

        if (filterTargets && filterTargets.length > 0) {
            commissionAccess = filterTargets[0].commissionAccess;
        }

        return commissionAccess;
    },

       

 
     /* Impersonation functions end */
 
     /**
      * Returns the distribution channel name for the reporting
      * role.
      * @returns {string|null} Distribution channel name.
      */
     getDistributionChannelName : function getDistributionChannelName () {
         var channelName = null;
         var activeRole  = this.producerRolesModel.get('activeRole');
 
         if (activeRole && activeRole.distributionChannel) {
             channelName = activeRole.distributionChannel;
         }
 
         return channelName;
     },
      /**
      * Returns the email for the reporting role.
      * @returns {string|null} email
      */

     getActiveRoleEmailContact : function getReportingPostalCode () {
         var email   = null;
         var activeContact;

         if (this.producerRolesModel.has('activeRole')) {
             activeContact = this.producerRolesModel.get('activeRole').contact;

             if (activeContact && activeContact.email) {
                 email = activeContact.email;
             }
         }

         return email;
     },
     /**
      * Returns the FullName for the reporting role.
      * @returns {string|null} fullName
      */ 

     getActiveFullName : function getReportingfullName () {
         var fullName   = null;
        
 
         if (this.userModel.has('delegation' ) && this.userModel.get('delegation').length>0)  {
             fullName= this.userModel.get('delegation')[0].producer.fullName;

         }

         return fullName;
     },
    /**
      * Returns the role code for the reporting role.
      * @returns {string|null} role code
      */
    
     getActiveRoleCode : function getReportingPostalCode () {
         var roleCode   = null;
        
         if (this.producerRolesModel.has('activeRole')) {
             roleCode = this.producerRolesModel.get('activeRole').roleCode;

         }

         return roleCode;
     },
 
     /**
      * Returns the reporting role code postal code.
      * @returns {string|null} Zip code
      */
     getReportingPostalCode : function getReportingPostalCode () {
         var postalCode    = null;
         var activeContact;
 
         if (this.producerRolesModel.has('activeRole')) {
             activeContact = this.producerRolesModel.get('activeRole').contact;
 
             if (activeContact && activeContact.post && activeContact.post.postalCode) {
                 postalCode = activeContact.post.postalCode;
             }
         }
 
         return postalCode;
     },
 
     /**
      * Returns OLS role of user from capabilities array.
      * @returns {string|null} The OLS role . 
      *                        Mostly expected roles are HO, PRODUCER, DELEGATE, PRODUCER-DELEGATE
      */
     getUserOLSRole : function getUserOLSRole () {
         var role   = null;
         var olsCapabilities = this.userModel.get('capabilities').filter(function (capability){
             if (capability.match(/^OLS:/)) {
                 if (capability.split(':').length === 2){
                     return capability;
                 }
             }
         });
         
         if (!_.isEmpty(olsCapabilities)) {
 
             // There are chance of two OLS capabilities for a user. 
             // If user has OLS:DELEGATE, OLS:PRODUCER together expected role is 
             // PRODUCER-DELEGATE. For that sorting it in descending order to
             // merge both with '-' and remove 'OLS:' from the capabilities
             role = olsCapabilities.sort().reverse().join('-').replace(/OLS:/g,'');
         }
 
         return role;
     },
 
     /**
      * Returns the state or province from the active role.
      * @returns {string|null} State/Province
      */
     getReportingStateProvince : function getReportingStateProvince () {
         var activeContact;
         var stateProvince = null;
 
         if (this.producerRolesModel.has('activeRole')) {
             activeContact = this.producerRolesModel.get('activeRole').contact;
 
             if (activeContact && activeContact.post && activeContact.post.stateProvince) {
                 stateProvince = activeContact.post.stateProvince;
             }
         }
 
         return stateProvince;
     },
 
     /**
      * Retrieves the reporting roles if a "producerId" attribute
      * exists in the model.
      * @private
      */
     _fetchProducerRoles : function _fetchProducerRoles () {
         var producerId = this.userModel.get('producerId');
 
         if (producerId && _.isNumber(producerId) && !this.producerRolesModel) {
             this.producerRolesModel = new ProducerRolesModel({
                 producerId : producerId
             });
 
             this.listenTo(this.producerRolesModel, 'sync', this._onProducerRolesRetrieved);
 
             this.producerRolesModel.fetch();
         }
     },
 
     _onProducerRolesRetrieved : function _onProducerRolesRetrieved () {
         this.trigger('state:ready:roles');
     },
 
     _sendUserChangeEvent: function () {
         this.triggerMethod('change', this.userModel.changedAttributes());
     },
 
     /**
     * Read the webId from the "persona" cookie if this code is in DEV or
     * TEST environments.
     *
     * If it isn't in one of these environments, this function returns
     * `undefined`.
     */
     _readWebIdFromCookie: function () {
         var webId;
 
         // If the user entered the OSO app through the devdata.html file,
         // they will have a "persona" cookie that contains a user id. In a
         // local development environment, the "persona" cookie is used by
         // the localdata-dev REST simulator to determine which static JSON
         // files are sent as responses to ajax requests from the OSO app.
         //
         // Unfortunately, we seem to need separate blocks for DEV and TEST
 
 
         return webId;
     }
 });
 
 
 module.exports = UserModule;
 