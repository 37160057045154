/* global _ */

let config = {
    apiBase           : '/api/oso/secure/rest/',
    apiBasePublic     : '/api/oso/public/web/',
    apiBaseDoc        : '/api/oso/secure/web/',
    salesForceBaseUrl : 'https://oasf.my.salesforce.com/',
    wcmBaseUrl        : '/wps/wcm/connect/indcontent/OSO/',
    defaultTimezone   : 'America/New_York'
};

config.errorMessages = {
    noPageFound : 'The page you requested does not exist.'
};

const envConfig = {

    // IMPORTANT: These 4 (or 3)-letter keys are matched to the first 4 (or 3)
    // letters in the URL hostname. Don't change them!

    // local development environments : http://localhost:3000
    loca : {
        debugEnabled     : true,
        defaultLoginPage : 'http://localhost:3000/devdata.html',
        pinpointURL      : 'http://localhost.com/devdata.html?COEPinpointglobal',
        pinpointResourceURL: 'https://oneamerica.uat3.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting',
        vintageURL       : 'https://www.stols.oneamerica.com/',
        oneamericaURL    : 'https://www.st.oneamerica.com',
        onesourceURL     : 'https://stonesourceonline.oneamerica.com',
        accNavURL        : 'https://login.dev.oneamerica.com/account-navigator',
        webTrends        : {
            dcsid        : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms   : 'ftonesourceonline.oneamerica.com',
            fpcdom       : '.ftonesourceonline.oneamerica.com'
        },

        // feature flag to enable / disable application feature 
        fflag : {
            enablePolicyHSSSOLink : true,
            displayPolicyHighlights: false,
            resourceCenterfilterGroup: true,
            displayInvestmentSection: true,
            dailyCommissionsTout: true,
            enableInforceIllusVUL: true,
            enablePremiumModeQuotes: true,
            enableInforceIllusLegacy121: true,
            enableResourceLink: true
        },

        // feature flag to enable / disable Menu Item
        menuItemFlag : {
            commissions : '',
            dailyCommission : '',
            oneDailyCompViewActivity : '',
            unitValues: ''
        }
    },

    // sandbox : https://sbonesourceonline.oneamerica.com
    sbon : {
        debugEnabled     : true,
        defaultLoginPage : 'https://www.sb.oneamerica.com/login',
        pinpointURL      : 'https://sbapi.oneamerica.com/oso/secure/web/sso/pinpoint',
        pinpointResourceURL: 'https://oneamerica.uat3.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting',
        vintageURL       : 'https://www.sbols.oneamerica.com/',
        oneamericaURL    : 'https://www.sb.oneamerica.com',
        onesourceURL     : 'https://sbonesourceonline.oneamerica.com',
        accNavURL        : 'https://login.sb.oneamerica.com/account-navigator',
        webTrends        : {
            dcsid        : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms   : 'ftonesourceonline.oneamerica.com',
            fpcdom       : '.ftonesourceonline.oneamerica.com'
        },

        fflag : {
            enablePolicyHSSSOLink : true,
            displayPolicyHighlights: false,
            resourceCenterfilterGroup: true,
            enableTempIcon           : true,
            displayInvestmentSection: true,
            dailyCommissionsTout: true,
            enableInforceIllusVUL: true,
            enablePremiumModeQuotes: true,
            enableInforceIllusLegacy121: true,
            enableResourceLink: true
        },

        // feature flag to enable / disable Menu Item
        menuItemFlag : {
            commissions : '',
            dailyCommission : '',
            oneDailyCompViewActivity : '',
            unitValues: ''

        } 
    },

    // sandbox : https://www.sbols.oneamerica.com    
    sbol : {
        debugEnabled     : true,
        webTrends        : {
            dcsid        : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms   : 'ftonesourceonline.oneamerica.com',
            fpcdom       : '.ftonesourceonline.oneamerica.com'
        }
    },

    // system test : https://stonesourceonline.oneamerica.com
    ston : {
        debugEnabled      : true,
        defaultLoginPage  : 'https://www.st.oneamerica.com/login',
        pinpointURL       : 'https://stapi.oneamerica.com/oso/secure/web/sso/pinpoint',
        pinpointResourceURL: 'https://oneamerica.uat3.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting',
        vintageURL        : 'https://www.stols.oneamerica.com/',
        oneamericaURL     : 'https://www.st.oneamerica.com',
        onesourceURL      : 'https://stonesourceonline.oneamerica.com',
        accNavURL         : 'https://login.dev.oneamerica.com/account-navigator',
        webTrends         : {
            dcsid         : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms    : 'ftonesourceonline.oneamerica.com',
            fpcdom        : '.ftonesourceonline.oneamerica.com'
        },

        // feature flag to enable / disable application feature 
        fflag : {
            enablePolicyHSSSOLink : true,
            displayPolicyHighlights: false,
            resourceCenterfilterGroup: true,
            enableTempIcon           : true,
            displayInvestmentSection: true,
            dailyCommissionsTout: true,
            enableInforceIllusVUL: true,
            enablePremiumModeQuotes: true,
            enableInforceIllusLegacy121: true,
            enableResourceLink: true
        },

        // feature flag to enable / disable Menu Item
        menuItemFlag : {
            commissions : '',
            dailyCommission : '',
            oneDailyCompViewActivity : '',
            unitValues: ''
        }
    },

    // system test : https://www.stols.oneamerica.com
    stol : {
        debugEnabled    : true,
        webTrends       : {
            dcsid       : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms  : 'ftonesourceonline.oneamerica.com',
            fpcdom      : '.ftonesourceonline.oneamerica.com'
        }
    },

    // function test : https://ftonesourceonline.oneamerica.com
    fton : {
        debugEnabled     : false,
        defaultLoginPage : 'https://www.ft.oneamerica.com/login',
        pinpointURL      : 'https://ftapi.oneamerica.com/oso/secure/web/sso/pinpoint',
        pinpointResourceURL: 'https://oneamerica.uat3.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting',
        vintageURL       : 'https://www.ftols.oneamerica.com/',
        oneamericaURL    : 'https://www.ft.oneamerica.com',
        onesourceURL     : 'https://ftonesourceonline.oneamerica.com',
        accNavURL        : 'https://login.test.oneamerica.com/account-navigator',
        webTrends        : {
            dcsid        : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms   : 'ftonesourceonline.oneamerica.com',
            fpcdom       : '.ftonesourceonline.oneamerica.com'
        },

        // feature flag to enable / disable application feature
        fflag : {
            enablePolicyHSSSOLink : true,
            displayPolicyHighlights: false,
            resourceCenterfilterGroup: false,
            enableTempIcon           : true,
            displayInvestmentSection: true,
            dailyCommissionsTout: true,
            enableInforceIllusVUL: true,
            enablePremiumModeQuotes: true,
            enableInforceIllusLegacy121: true,
            enableResourceLink: true
        },

        // feature flag to enable / disable Menu Item
        menuItemFlag : {
            commissions : '',
            dailyCommission : '',
            oneDailyCompViewActivity : '',
            unitValues: ''
        } 
    },

    // function test : https://www.ftols.oneamerica.com
    ftol : {
        debugEnabled    : false,
        webTrends       : {
            dcsid       : 'dcs222ry8pkjqwz2mhuqmdtim_6c9j',
            onsitedoms  : 'ftonesourceonline.oneamerica.com',
            fpcdom      : '.ftonesourceonline.oneamerica.com'
        }
    },

    // production (new stack): https://onesourceonline.oneamerica.com
    ones : {
        debugEnabled     : false,
        defaultLoginPage : 'https://www.oneamerica.com/login',
        pinpointURL      : 'https://api.oneamerica.com/oso/secure/web/sso/pinpoint',
        pinpointResourceURL: 'https://oneamerica.pinpointglobal.com/Portal/resourcepages/underwriting/underwriting',
        vintageURL       : 'https://www.ols.oneamerica.com/',
        oneamericaURL    : 'https://www.oneamerica.com',
        onesourceURL     : 'https://onesourceonline.oneamerica.com',
        accNavURL        : 'https://login.oneamerica.com/account-navigator',
        webTrends        : {
            active       : true,
            dcsid        : 'dcs222dn4iur9k64ed0auexqc_3q4w',
            onsitedoms   : 'onesourceonline.oneamerica.com',
            fpcdom       : '.onesourceonline.oneamerica.com'
        },
        
        // feature flag to enable / disable application feature 
        fflag : {
            enablePolicyHSSSOLink : true,
            enableInforceIllusWL7702 : true,
            displayPolicyHighlights: false,
            resourceCenterfilterGroup: false,
            enableTempIcon           : true,
            displayInvestmentSection: true,
            dailyCommissionsTout: true,
            enableInforceIllusVUL: true,
            enablePremiumModeQuotes: true,
            enableInforceIllusLegacy121: true,
            enableResourceLink: true
        },

        // feature flag to enable / disable Menu Item
        menuItemFlag : {
            commissions : '',
            dailyCommission : '',
            oneDailyCompViewActivity : '',
            unitValues: ''

        } 
    },

    // production (old stack) : https://www.ols.oneamerica.com
    ols : {
        debugEnabled    : false,
        webTrends       : {
            dcsid       : 'dcs222dn4iur9k64ed0auexqc_3q4w',
            onsitedoms  : 'onesourceonline.oneamerica.com',
            fpcdom      : '.onesourceonline.oneamerica.com'
        }
    }

};

/*
* Method to get config based on hostname
*
* @param hostname - string
*/
config.getAjaxConfig = function (hostname) {

    if (!hostname || !_.isString(hostname)) {
        throw new Error('Config.getAjaxConfig : parameter hostname should be a string');
    } 

    let envPrefix = hostname.slice(0,2);

    // If this code is running within the old stack (e.g. Performance Center),
    // all of the urls will start with "www." and that needs to be ignored.
    if (envPrefix === 'ww') {
        // skip the 'www.'
        envPrefix = hostname.slice(4,8);

        //replace ols. with ols
        envPrefix = envPrefix.replace(/\./,'');

    //to run with new api URL which don't have www. prefix
    } else {
        envPrefix = hostname.slice(0,4);
    }

    // Use the config settings that correspond to the first 4 (or 3) letters
    // of the hostname.
    if (envConfig[envPrefix]) {
        config = _.extend(config, envConfig[envPrefix]);
    }

    config.apiUrlRoot       = config.apiBase;
    config.apiDocUrlRoot    = config.apiBaseDoc;
    config.apiPublicUrlRoot = config.apiBasePublic;

    return config;
};

//load configs based on hostname
config = config.getAjaxConfig(window.location.hostname);

module.exports = config;
