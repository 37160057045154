/* global require:false, Backbone:false, Marionette:false, _:false */
/**
 * Module encapsulating the OSO Marionette application.
 */
import ajaxUtils from './utils/ajax-utils';
import analyticsSingleton from './modules/analytics/analyticsModule';
import AppStructureModel from './models/appStructure-m';
import ContentLayout from './modules/content/contentLayout-v';
import checkpointModule from './modules/checkpoint/checkpointModule';
import config from './config/config';
import debugModule from './modules/debug/debugModule';
import Footer from './modules/footer/index';
import GoogleTagManagerProvider from './modules/analytics/providers/googleTagManagerProvider';
import idleWatcherSingleton from './modules/idle/idleModule';
import NavBar from './modules/navbar/navbarModule';
import PageLookupModel from './models/pageLookup-m';
import RootLayout from './views/root-layout-v';
import Sidebar from './apps/sidebar/index';
import SpinnerModule from './modules/waitIndicator/spinnerModule';
import UserModule from './modules/user/userModule';
import utils from './utils/utils';
import WcmStructureModel from './models/wcmStructure-m';
import WcmView from './modules/wcm-content/views/wcm-content-v';
import WebTrendsProvider from './modules/analytics/providers/webtrendsAnalyticsProvider';
import ViewAsProducerRibbonView from './modules/viewAsProducer/vap-ribbon-v';
import rewriteDeprecatedURL from './utils/rewriteDeprecatedURL';

const dbg = debugModule.init();

//browser detection module 
import './modules/browserFeatureDetection/browserFeatureDetectionModule';

const Radio = Backbone.Radio;

// Just wanted to throw this in there while I was thinking about it
// Can take this out as needed.
// Would only be true in DEV mode then.
Radio.DEBUG = 'undefined';
Radio.DEBUG = (Radio.DEBUG === 'true');

// load behaviors
import './modules/behaviors';

const ajaxSetup = (configObj) => {
    // beforeSend adds the URL to the jqXHR object so that it is available in error handlers
    Backbone.$.ajaxSetup({
        beforeSend: ajaxUtils.beforeSend
    });

    // Set up the error handler. Pass in the userModule.logout function to use in the case
    // the user encounters a 401 response.
    Backbone.$(document)
        .ajaxError(ajaxUtils.buildErrorHandler(configObj.user.logout));

    Backbone.$.ajaxPrefilter(ajaxUtils.buildPrefilter(configObj.user));
};

/*
 OSO uses Backbone.BaseRouter instead of the built-in Backbone router.
 BaseRouter calls the same onNavigate(..) function no matter what route is
 requested. See https://github.com/jmeas/backbone.base-router
 
 OSO's instance of BaseRouter is instantiated in the OSOapp._configureRouter()
 function where it binds BaseRouter.onNavigate() to OSOapp._routerOnNavigate()
 so it can call other OSOapp functions. This coupling of the BaseRouter
 instance with OSOapp is the reason why the router is kept here in osoApp.js
 rather than in a separate module.
*/
const OSORouter = Backbone.BaseRouter.extend({

    /*
     The ordering of the routes is important!
     The router will take the first matching route from the below hash.
     */
    routes: {
        '': 'home',
        'c(/)*pageId': 'wcm',
        ':pageId(/)(*subpages)': 'page'
    }
});

const OSOapp = Marionette.Application.extend({

    analytics: analyticsSingleton,
    idleWatcher: idleWatcherSingleton,
    rewriteURL: rewriteDeprecatedURL,
    userModule: null,
    errors: {
        jQueryDeparamMissing: '_getTargetURL : Missing $.deparam plugin'
    },

    /**
    * Executes immediately after the OSOapp is instantiated in app.js
    */
    initialize() {
        dbg.log('OSOapp.initialize');
        let wcmStructureModel = new WcmStructureModel();

        //defining global errorChannel to show error messages by invoking a channel method
        this.errorChannel = Backbone.Radio.channel('error');

        this.userModule = new UserModule();

        this.listenTo(this.userModule, 'viewAsProducer', this._viewAsProducer);
        this.listenTo(this.userModule, 'endViewAsProducer', this._endViewAsProducer);

        ajaxSetup({ user: this.userModule });

        this.appStructureModel = new AppStructureModel();

        //appending wcm structure with app navigation Structure
        this.appStructureModel.appendStructure(
            wcmStructureModel.get('structure'),
            WcmView
        );

        this.pageLookupModel = new PageLookupModel({
            appStructure: this.appStructureModel.get('structure')
        });

        wcmStructureModel = null;

        utils.addDataTableCustomSorts();

        //initialize Spinner module
        //it will get ready with Radio channel 'spinner' to show/hide methods
        ///to trigger

        this.spinner = new SpinnerModule();
        this.spinnerChannel = Backbone.Radio.channel('spinner');

        // attach config for testing
        this.config = config;
    },

    /**
    * Executes as a result of the Application "start" event after the initialize
    * function completes.
    */
    onStart() {
        dbg.log('OSOapp.onStart');

        //registering a method to call the error channel
        this.listenTo(this.errorChannel, 'showErrorPage', this._showErrorPage);

        // Rewrite deprecate URLs with new one
        this.rewriteURL.rewrite();

        this.requestUserData();
        this.addUIComponents();

        this.spinnerChannel.trigger('show', {
            viewScope: this,
            position: 'fixed'
        });
    },

    /**
    * Tells the user module to fetch the user data from the service. When the data
    * is ready, execute OSOapp.userReadySetup() to complete OSO's initialization.
    */
    requestUserData() {
        dbg.log('OSOapp.requestUserData');

        // custom event fire from userModule once user data is ready
        this.userModule.once('state:ready', this.userReadySetup, this);

        // custom event fired from userModule once when/if producer roles are ready
        this.userModule.once('state:ready:roles', this.userRolesReady, this);
        // initialize user data
        this.userModule.fetchUserData();
    },

    /**
    * Create the app's root layout view and add the navbar and footer, which are the
    * two top-level UI components that can be rendered before the user data is ready.
    */
    addUIComponents() {
        dbg.log('OSOapp.addUIComponents');

        this.rootView = new RootLayout();
        this.rootView.render();

        this.navbar = new NavBar({
            region: this.rootView.getRegion('topBarRegion'),
            userName: this.userModule.getDisplayName(),
            appName: 'OneSource Online',
            producerId: this.userModule.getProducerId,
            homeOffice: this.userModule.hasCapability('Home_Office')
        });

        this.listenTo(this.navbar, 'nav', this._handleNavEvent);
        this.listenTo(this.navbar, 'logout', this._logout);
        this.listenTo(this.navbar,
            'maintainDelegateAccess',
            this._maintainDelegateAccess
        );

        this.listenTo(this.navbar,
            'startProducerDelegateAccess',
            this._startDelegateAccess
        );

        this.listenTo(this.navbar,
            'securitySettings',
            this._securitySettings
        );

        // Footer
        this.footer = new Footer({
            region: this.rootView.getRegion('footerRegion')
        });

        //get appStructure which is accessible to public
        this.appStructure = this.appStructureModel.filterForSidebar();

        //sidebar app will be prepending into container element
        this.sidebarApp = new Sidebar({
            container: '.main-container',
            menu: this.appStructure
        });
        this.sidebarApp.start();
        this.listenTo(this.sidebarApp, 'nav', this._handleNavEvent);

        // Add content layout
        this.contentLayout = new ContentLayout({
            pageLookup: this.pageLookupModel.get('structure')
        });

        this.rootView.getRegion('contentRegion').show(this.contentLayout);

        this.listenTo(this.contentLayout, 'nav', this._handleNavEvent);
    },

    /**
     * Fired when the userModel has successfully retrieved the
     * producer roles.
     */
    userRolesReady() {
        dbg.log('OSOapp.userRolesReady');

        // Add the role and channel to analytics
        this.analytics.setDataLayerValue({
            'event':   'channelInfo',
            'Channel':   this.userModule.getDistributionChannelName(),
            'State':     this.userModule.getReportingStateProvince(),
            'Zip Code':  this.userModule.getReportingPostalCode(),
            'Email' :    this.userModule.getActiveRoleEmailContact(),
            'Role Code': this.userModule.getActiveRoleCode()  
        }); 
    },

    /**
    * Now that the user data is loaded, start up all of the UI components and other
    * modules that consume the user data.
    */
    userReadySetup() {
        dbg.log('OSOapp.userReadySetup');

        const userCapabilities = this.userModule.getCapabilities();

        // Check these Crusty Old Capabilities (which come from ELAD)
        const isDelegate = this.userModule.hasCapability('OLS:DELEGATE');
        const isProducer = this.userModule.hasCapability('OLS:PRODUCER');
        const isDelegateMaintainer = this.userModule.hasCapability('Delegate_Maintenance');

        const impersonatedProducerInfo = this.getImpersonatedDataFromQueryString();

        this.appStructure = this.appStructureModel.filterForSidebar(userCapabilities);

        this.idleWatcher.start({
            callback: _.bind(this.userModule.timeout, this.userModule)
        });

        // initialize analytics.
        this.analytics.init({
            providers: this._getAnalyticsProviders(),
            webId: this.userModule.getWebId()
        });
        this.analytics.setDataLayerValue({
            'event':        'login',
            'User ID':      this.userModule.getWebId(),
            'Display Name': this.userModule.getDisplayName(),
            'Role':         this.userModule.getUserOLSRole(),
            'Full Name':    this.userModule.getActiveFullName(),
            'Capabilities': userCapabilities
        });
        
        
        // TODO: find out if we're actually using this
        this.listenTo(this.userModule, 'change', this._handleUserChange);
        this.listenTo(this.userModule, 'change', this._handleHO);

        // Handle initialization for user based on delegate status
        if (isDelegate && !isProducer && !impersonatedProducerInfo) {

            this._startDelegateAccess();

        } else {

            if (isDelegate && isProducer) {

                // We need to update the utility menu in the navbar to add the 'delegation' option
                this.navbar.showProducerDelegateAccessMenuOptions(true);
            }

            if (isDelegateMaintainer && isProducer) {
                this.navbar.showDelegateMaintenanceOption(true);
            }

            if (impersonatedProducerInfo) {
                this.spinnerChannel.trigger('show', {
                    viewScope: this,
                    position: 'fixed'
                });
                this.userModule.beginImpersonation(impersonatedProducerInfo);
            } else {

                // re-initialize with user's app structure
                this.sidebarApp.reinitializeMenu(this.appStructure);

                this._configureRouter();

                // For Home/Producer user redirect to target URL 
                // if URL has 'targeturl' query parameter
                const targetUrl = this._getTargetURL();
                if (targetUrl) {
                    location.href = targetUrl;
                }
            }
        }

        // update navbar
        this.navbar.updateUserName(this.userModule.getDisplayName());
        this.navbar.updateHO(this.userModule.hasCapability('Home_Office'));

        // Call showBanner() in content layout. Since this requires checking of a user's
        // capabilities to retrieve the appropriate content, the call is placed in this method
        // rather than addUIComponents.
        this.contentLayout.showBanner();
    },

    /**
     * Clean up
     * Useful for unit tests
     */
    onBeforeDestroy() {
        this.stopListening();
    },

    /**
     * Returns a collection of providers.
     * Note: OneAmerica wants to use WebTrends in
     * production only in order to reduce licensing costs.
     * @see(@link OOSO-3481|https://oneamerica.atlassian.net/browse/OOSO-3481}
     * @returns {Array}
     * @private
     */
    _getAnalyticsProviders() {
        let providers = [this._getGoogleTagManagerProvider()];

        if (this.config.webTrends.active) {
            providers.push(new WebTrendsProvider());
        }

        return providers;
    },

    /**
    * Event handler for 'change' event in the user module.
    *
    * @param {object} changedAttributes - user model attributes that have changed
    */
    _handleUserChange(changedAttributes) {
        dbg.log('OSOapp._handleUserChange');

        if (changedAttributes && changedAttributes.displayName) {
            this.navbar.updateUserName(changedAttributes.displayName);
        }
    },

    _handleHO(changedAttributes) {
        dbg.log('OSOapp._handleHO');

        if (changedAttributes && changedAttributes.hasCapability('Home_Office')) {
            this.navbar.updateHO(changedAttributes.hasCapability('Home_Office'));
        }
    },

    /**
    * Event handler for 'nav' events triggered by OSOapp's child components.
    * Updates the URL hash and shows the page.
    *
    * @param {string} hash - The URL hash from the nav event
    */
    _handleNavEvent(hash) {
        dbg.log('OSOapp._handleNavEvent');

        let pagePath = hash.split('?')[0];
        let pageId = pagePath.split('/')[0];
        const subpages = pagePath.replace(/[^&$/]+/, ''); // remove pageId
        let stateObj;

        // We need to check for wcm, which uses the entire bit before the query string as the ID
        if (pageId === '#c') {
            pageId = pagePath;
        }

        // Get home page for delegate access 
        let homePageForDelegateAccess = this._getHomePageIdForDelegateUser(pageId);
        if (homePageForDelegateAccess) {
            pageId = hash = homePageForDelegateAccess;
        }

        if (!this._isValidPageId(pageId) || !this._userHasPagePermissions(pageId)) {
            return;
        }

        // Add 'targetuser' query param to URL if impersonateWeb Id exist in user module
        hash = utils.addTargetUserQueryParamToURL(hash);

        // Update the fragment identifier (hash) in the browser's URL
        Backbone.history.navigate(hash);
        stateObj = checkpointModule.readCheckpoint();

        //add subpages to stateObj if there are any
        stateObj = this._addSubpagesToStateObj(stateObj, subpages);

        this._showPage(pageId, stateObj);
    },

    /**
    * Show a page in the OSO app and update sidebar active item
    *
    * @param {string} pageId - Identifies which page to show. Must match a `link` property
    *                          of an item in appStructure-m.js
    * @param {object} [stateObj] - Optional object that a page can use to initialize itself
    */
    _showPage: function (pageId, stateObj) {
        dbg.log('OSOapp._showPage("' + pageId + '")');

        const siteAreaPageId = this.pageLookupModel.getSiteAreaPageIdFrom(pageId);
        if (siteAreaPageId !== null) {
            pageId = siteAreaPageId;
        }

        //set active sidebar item based on desired page id
        this.sidebarApp.setActiveItem(pageId);

        //Show content
        this.contentLayout.showPage(pageId, stateObj);
    },

    /**
    * Log out the current user
    */
    _logout() {
        this.userModule.logout();
    },

    /**
    * Send user to Security Access Settings page
    */
    _securitySettings() {
        this.userModule.securitySettings();
    },

    _maintainDelegateAccess() {
        this._handleNavEvent('#producer-delegate-maintenance?producerId='
            + this.userModule.getProducerId());
    },
    /**
    * Create and configure OSOapp's instance of BaseRouter.
    */
    _configureRouter() {
        if (!this.router) {
            this.router = new OSORouter();

            // Must bind the _routerOnNavigate function to this (OSOapp) since it will be
            // called from the base-router code, which is in the wrong execution context.
            this.router.onNavigate = _.bind(this._routerOnNavigate, this);

            // Added check for Backbone.History.started because the start() call was throwing an
            // error stating that history was already started while running unit tests. Should be
            // started after any routers are set up. - JRB
            if (!Backbone.History.started) {
                Backbone.history.start();
            }
        }
    },

    /**
    * Called by Backbone.BaseRouter to handle "navigate" events
    *
    * @param {object} routeData - Data structure created by BaseRouter with properties to
    *                             describe the current route navigation request.
    * @see {@link https://github.com/jmeas/backbone.base-router#routedata}
    */
    _routerOnNavigate(routeData) {
        dbg.log('OSOapp._routerOnNavigate', routeData);

        let desiredPageId = 'home'; // defaults to home page
        let stateObj = {};

        // Get the pageId to display, if present
        if (routeData && routeData.params && routeData.params.pageId) {
            desiredPageId = routeData.params.pageId;

            if (routeData.linked === 'wcm') {
                desiredPageId = 'c/' + desiredPageId;
            }
        }

        /******************** VaP / Delegation user handling***************************/

        if (routeData && routeData.query) {
            // If the URL has the deprecated "as" query param, move the value to "targetuser"
            // This scenario exists for handling any bookmarks which may still
            // include the "as" param.
            if (routeData.query.as && !routeData.query.targetuser) {
                routeData.query.targetuser = routeData.query.as;
                delete routeData.query.as;
            }

            // Check whether routeData contains the 'targetuser' query param
            // If that exists then start impersonation with value of 'targetuser'.
            if (routeData.query.targetuser && !this.userModule.getImpersonatedWebId()) {

                let impersonatedProducerInfo = this.getImpersonatedDataFromQueryString();

                // if producer info exist then start impersonation
                if (impersonatedProducerInfo) {
                    this.spinnerChannel.trigger('show', {
                        viewScope: this,
                        position: 'fixed'
                    });
                    this.userModule.beginImpersonation(impersonatedProducerInfo);
                    return;
                }

                // If there is no 'targetuser' query param (that mean app moving to
                // authenticated users page not in delegation target users) and app running on
                // impersonated state
            } else if (!routeData.query.targetuser && this.userModule.getImpersonatedWebId()) {

                const isDelegate = this.userModule.hasCapability('OLS:DELEGATE', true);
                const isProducer = this.userModule.hasCapability('OLS:PRODUCER', true);
                const targetProducers = this.userModule.getDelegationTargets();

                // It is not applicable for Delegation ONLY user with single targets
                // because there is no END of impersonation
                if (!(isDelegate && !isProducer && targetProducers.length === 1)) {

                    // false stand for no redirection after end of delegation
                    this.userModule.endImpersonation(false);
                }
            }
        }

        /******************** END VaP / Delegation user handling***************************/

        // verify that a valid pageId was passed and the user has permissions
        if (!this._isValidPageId(desiredPageId) || !this._userHasPagePermissions(desiredPageId)) {
            return;
        }

        // Get the query object from routeData, if present
        if (routeData && routeData.query && !_.isEmpty(routeData.query)) {
            stateObj = routeData.query;
        }

        // add subpages to stateObj if there are any
        if (routeData && routeData.params) {
            const subpages = routeData.params.subpages;
            stateObj = this._addSubpagesToStateObj(stateObj, subpages);
        }

        this._showPage(desiredPageId, stateObj);
    },

    /**
     * Add sub pages param to stateObj
     * @param {object} stateObj state object
     * @param {string} subpages subpage string with back slash (/)
     * @return {object} updated state object
     */
    _addSubpagesToStateObj(stateObj, subpages) {

        if (!stateObj || !_.isObject(stateObj)) {
            stateObj = {};
        }

        if (subpages) {

            //overwrite subpages with array
            subpages = subpages.split(/\//g);
            stateObj.subpages = [];
            _.each(subpages, function (value, index) {
                if (value) {
                    stateObj.subpages.push(value);
                }
            });
        }

        return stateObj;
    },


    /**
     * Show error page
     *
     * @param {string} message - a message display on the page
     */
    _showErrorPage(message) {
        let stateObj = {
            message: message
        };
        Backbone.history.navigate('error');
        this._showPage('error', stateObj);
    },


    /**
     * Verifies that the pageId is valid in the application and performs error handling if not.
     * @param {string} pageId
     * @returns {boolean}
     * @private
     */
    _isValidPageId(pageId) {
        if (this.pageLookupModel.isValidPage(pageId)) {
            return true;
        }

        let errorMessage = 'Invalid pageId "' + pageId + '" passed to OSOapp._handleNavEvent';

        dbg.log(errorMessage);
        this.analytics.trackException({
            fatal: false,
            message: errorMessage
        });

        this.errorChannel.trigger('showErrorPage', this.config.errorMessages.noPageFound);
        return false;
    },

    /**
     * _userHasPagePermissions checks the users permissions
     * to the page and performs any error handling
     * @param {string} pageId
     * @returns {boolean}
     * @private
     */
    _userHasPagePermissions(pageId) {
        if (this.pageLookupModel.userCanAccessPage(pageId, this.userModule.getCapabilities())) {
            return true;
        }

        let errorMessage = 'User tried to access ' + 'pageId "' + pageId +
            '" and did not have the required capability.';

        dbg.log(errorMessage);
        this.analytics.trackException({
            fatal: false,
            message: errorMessage
        });
        this.errorChannel.trigger('showErrorPage', this.config.errorMessages.noPageFound);
        return false;
    },

    /**
     * View as a producer. This will:
     * 1) Reinitialize the sidebar with the producer's items in the menu.
     * 2) Show the VaP ribbon.
     * 3) Route to the home page.
     *
     * @private
     */
    _viewAsProducer(isDelegate, hasMultiple, isProducer, redirectHash) {

        let userCapabilities;
        const commissionInfo = this.userModule.getCommissionInfo();
        if (typeof commissionInfo !== undefined && commissionInfo !== null) {
            if((this.userModule.hasCapability('Benefits_Retail_GeneralAgent')||
            this.userModule.hasCapability('Benefits_Retail')) && !commissionInfo){
                userCapabilities = this.userModule.getCapabilities().filter(function (item) {
                    return (item !== 'Benefits_Retail_GeneralAgent'&&
                    item !=='Benefits_Retail');
                });
            }else {
                userCapabilities = this.userModule.getCapabilities();
            }
        } else {
            userCapabilities = this.userModule.getCapabilities();
        }
        const menuStructure = this.appStructureModel.filterForSidebar(userCapabilities);
        

        let label = isDelegate ? 'Delegate for' : 'Viewing as';
        let endLinkLabel = isDelegate ? 'End Delegation' : 'End View as Producer';
        let showEnd = false;
        let vapRibbonView;

        this.sidebarApp.reinitializeMenu(menuStructure);
        this.navbar.reRender();

        this._configureRouter();

        // Show end user link
        // Link should appear only for
        // 1. VaP users
        // 2. Delegate user with multiple producers
        // 3. Producer Delegate users
        if (!isDelegate || (isDelegate && hasMultiple) || (isDelegate && isProducer)) {
            showEnd = true;
        }

        // Redirect to target URL if 'targeturl' query exist in URL
        let targetUrl = this._getTargetURL();
        if (targetUrl) {
            location.href = targetUrl;
            return false;
        }

        if (redirectHash) {

            //Navigate to redirectHash
            this._handleNavEvent(redirectHash);
        } else {

            //Navigate to home
            this._handleNavEvent('#home');
        }

        // Add a green ribbon for impersonated user
        vapRibbonView = new ViewAsProducerRibbonView({
            label: label,
            endLinkLabel: endLinkLabel,
            producer: this.userModule.getImpersonatedUserName(),
            showEnd: showEnd
        });

        this.contentLayout.showRibbon(vapRibbonView);

        // re-load the banner
        // The reason for moving this statement here is to complete nav routing 
        // to understand current page from Backbone.history.
        // So based on page type we need to collpase banner
        this.contentLayout.showBanner();

        // Finally scroll to top if scroll position is higher than 0
        if (Backbone.$(window).scrollTop() > 0) {
            utils.scrollTo(Backbone.$('body'));
        }
    },

    /**
     * End the View as Producer session. This will:
     * 1) Reinitialize the sidebar with the logged-in user's nav items.
     * 2) Remove the VaP ribbon.
     * 3) Route the user to the search page
     *
     * @private
     */
    _endViewAsProducer(isDelegate, hasMultiple, isProducer, redirectFlag) {

        const userCapabilities = this.userModule.getCapabilities();
        const menuStructure = this.appStructureModel.filterForSidebar(userCapabilities);

        this.sidebarApp.reinitializeMenu(menuStructure);
        this.contentLayout.removeRibbon();

        if (redirectFlag === false && redirectFlag !== undefined) {
            return;
        }

        if (isDelegate && hasMultiple && !isProducer) {

            //take user to Home page of delegate ONLY (multiple producers)
            this._handleNavEvent('#delegate-producer-list');
        } else if (isDelegate && isProducer) {

            //take user to Home Page for the logged in producer “self” 
            this._handleNavEvent('#home');
        } else {

            //add param to make 'view as producer' option checked
            this._handleNavEvent('#search?searchType=producerNumber');
        }

        // re-load the banner
        // The reason for moving this statement here is to complete nav routing
        // to understand current page from Backbone.history.
        // So based on page type we need to collpase banner
        this.contentLayout.showBanner();
    },

    /**
     * A method to start delegation access for :                        
     *  1. Only delegation  with single / multiple producer delegation list
     *  2. Producer delegation with single / Multiple producer delegation list
     *     This is invoked from navBar module through 'startProducerDelegateAccess' event
     *  
     */
    _startDelegateAccess() {

        // check whether user is producer and running an impersonation state
        let isProducer;
        let isDelegate;
        let hasImpersonatedWebId = this.userModule.getImpersonatedWebId();

        // Get the producers this user can be a delegate for (will be an empty array if none)
        let targetProducers = this.userModule.getDelegationTargets();

        if (hasImpersonatedWebId) {

            //checking userModel instead of impersonateModel in userModule
            isProducer = this.userModule.hasCapability('OLS:PRODUCER', true);
            isDelegate = this.userModule.hasCapability('OLS:DELEGATE', true);

            // If a producer and has multiple target producers, then end
            // current impersonate state take user to 'Delegate Access page'
            if (isDelegate && isProducer && targetProducers && targetProducers.length > 1) {
                this.userModule.endImpersonation();
            }
        }

        // If the user has only one valid producer in delegation, then start impersonation
        if (targetProducers && targetProducers.length === 1) {
            this.userModule.beginImpersonation({
                webId: targetProducers[0].producer.webId,
                fullName: targetProducers[0].producer.fullName
            });
        } else {

            // OOSO-3171 and OOSO-3172
            // Delegate User/ Producer delegate users has 0 or more than 1. 
            // Take the user to the home page, with a list
            // to select which producer to act as (or if count is 0, display some sort of
            // message).

            // re-init with delegate's app structure
            this.sidebarApp.reinitializeMenu(this.appStructure);
            this._configureRouter();

            this._handleNavEvent('#delegate-producer-list');
        }
    },

    /**
     * Method only for DELEGATE user to get their Home
     * 
     * Get desired "Home page ID" for delegate user while clicking on Home link 
     * or when user is navigating through browser history using backButton
     * 
     * @param  {string} pageId current page from router
     * @return {string} pageId desired home page id will returned as string
     */
    _getHomePageIdForDelegateUser(pageId) {
        const isDelegate = this.userModule.hasCapability('OLS:DELEGATE');
        const hasImpersonatedWebId = this.userModule.getImpersonatedWebId();

        if (!pageId || (!hasImpersonatedWebId && !isDelegate)) {
            return null;
        }

        const isProducer = this.userModule.hasCapability('OLS:PRODUCER');
        const targetProducers = this.userModule.getDelegationTargets();

        //remove # from pageId if that exist
        pageId = pageId.replace('#', '');

        let desiredPageId;

        // If user is delegate ONLY (not producer) with mutliple producers 
        // delegation list their home should be #delegate-producer-list
        if (pageId === 'home' && isDelegate && !isProducer
            && targetProducers && targetProducers.length > 1) {
            desiredPageId = 'delegate-producer-list';
        }

        return desiredPageId;
    },

    /**
     * Get impersonated producer info if current url state has 'targetuser' or the
     * deprecated 'as' query param to re-initiate VaP / Delegate session.
     *
     * {@link https://oneamerica.atlassian.net/browse/OOSO-3793|OOSO-3793}
     * 
     * @return {object} producerInfo Object with producer info 
     *                               such as webId and Full name (if exist)
     */
    getImpersonatedDataFromQueryString() {
        const currentStateObj = checkpointModule.readCheckpoint();
        let producerInfo;

        const isDelegate = this.userModule.hasCapability('OLS:DELEGATE');
        let redirectHash = location.hash;

        // if state contain 'targetuser' query param
        if (currentStateObj) {

            // Copy the 'as' parameter to 'targetuser' for old bookmarked links.
            if (currentStateObj.as && !currentStateObj.targetuser) {
                currentStateObj.targetuser = currentStateObj.as;
            }

            if (currentStateObj.targetuser) {
                if (isDelegate) {
                    producerInfo = this.userModule.getDelegationTarget(currentStateObj.targetuser);
                } else {
                    producerInfo = {
                        webId: currentStateObj.targetuser
                    };
                }
            }

            // Some case user might edit the 'targetuser' query parameter,
            // so producer info will be null in case of delegate access.
            if (producerInfo && _.isObject(producerInfo)) {
                producerInfo.redirectHash = redirectHash;
            }
        }

        return producerInfo;
    },

    /**
     * Get a URL specified in targeturl query parameter
     * specified in application URL.
     * 
     * @param {string} [url] optional
     * @param {boolean} [returnTargetUrl] optional - return targeturl
     */
    _getTargetURL(url) {
        let domain;
        let host;
        let impersonatedWebId;
        let queryParameters;
        let separator;
        let targetURL;
        let urlSearch;

        // Check existence of deparam jQuery plugin
        if (!Backbone.$.deparam) {
            throw new Error(this.errors.jQueryDeparamMissing);
        }

        if (url) {
            urlSearch = url.split('?')[1];
            queryParameters = Backbone.$.deparam(urlSearch ? urlSearch : '');
        } else {
            queryParameters = Backbone.$.deparam(location.search.replace('?', ''));
        }

        targetURL = queryParameters.targeturl;

        if (targetURL) {

            // URL should start with https:// or http:// 
            host = targetURL.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
            if (host && host[1]) {
                domain = host[1].split('.').slice(-2).join('.');
                if (domain === 'oneamerica.com') {

                    // Check whether user is in impersonate state.
                    // If then add targetuser query param to targeturl 
                    impersonatedWebId = this.userModule.getImpersonatedWebId();
                    if (impersonatedWebId) {
                        separator = targetURL.indexOf('?') !== -1 ? '&' : '?';
                        targetURL = targetURL + separator + 'targetuser=' + impersonatedWebId;
                    }
                    return targetURL;
                } else {
                    dbg.error('osoApp._getTargetURL - Invalid domain : ' + domain);
                }
            } else {
                dbg.error('osoApp._getTargetURL - Invalid targeturl : ' + targetURL);
            }
        }
    }, // _getTargetURL

    /**
     * Load the data layer for Google Tag Manager using pre-defined
     * keys configured in GTM.
     * @see {@link https://oneamerica.atlassian.net/browse/OOSO-3660|OOSO-3660}
     * @return {object} gtmProvider - A configured instance of GoogleTagManagerProvider.
     * @private
     */
    _getGoogleTagManagerProvider() {
        const gtmProvider = new GoogleTagManagerProvider();
        // For now, we will only load the data layer once -- even if impersonation takes place.
        return gtmProvider;
    }
});

module.exports = OSOapp;
